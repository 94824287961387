<template>
  <div>  
    <CRow>
      <CCol lg="12">
        <CButton color="info" size="sm" @click="link('home')" style="float: right;">Inicio</CButton>
        <h2>INFORMACIÓN DE LA RUTA</h2>
      </CCol>
    </CRow> 
    <CRow >
      <CCol :md="12" >
        <CCard >
          <CCardBody>
            <CRow >
              <CCol :md="2">
                <strong>Nombre:</strong>
                <br />
                <strong>Promotor:</strong>
              </CCol>
              <CCol :md="3">
                <span style="float: left;">{{collaborator.route_name}}</span>
                <br />
                <span style="float: left;">{{collaborator.name}}</span>
              </CCol>
              <CCol :md="3"></CCol>
              <CCol :md="2">
                <strong>Usuario:</strong>
                <br />
                <strong>Contraseña:</strong>
              </CCol>
              <CCol :md="2">
                <span style="float: right;">{{collaborator.user.email}}</span>
                <br />
                <span style="float: right;">{{collaborator.user.contrasena ? collaborator.user.contrasena : '******'}}</span>
              </CCol>                               
            </CRow>
          </CCardBody>
        </CCard>   
      </CCol>
    </CRow>   
    <br>
    <CRow>
      <CCol :md="4" >
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Universo Quincena 1 
            </slot>
          </CCardHeader>
          <CCardBody :style="'height: '+setMetricHeight()+'px;'">
            <CRow v-if="metrics_1.visits.length > 0">
              <CCol :md="12">
                <CChartPie
                  :datasets="defaultDatasets1"
                  :labels="graph_1[0]"
                />
              </CCol>
              <CCol :md="12" >
                <br>
                <CDataTable
                  :hover="hover"
                  :striped="striped"
                  :border="border"
                  :small="small"
                  :fixed="fixed"
                  :items="metrics_1.visits"
                  :fields="fieldsMetric"
                  :items-per-page="5"
                  pagination
                  class="table-metrics"                  
                >
                  <template #marca="{item}"><td class="text-left">{{ item.customer_name }}</td></template>
                  <template #universo="{item}"><td class="w100 text-center">{{ item.total }}</td></template>
                  <template #realizado="{item}"><td class="w100 text-center">{{ item.done }}</td></template>      
                  <template #validated="{item}"><td class="w100 text-center">{{ item.validated }}</td></template>      
                  <template #porcentaje="{item}"><td class="w100 text-center">{{ Math.round(item.rate) }} %</td></template>
                </CDataTable>
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-center"><strong> TOTAL</strong></td>
                        <td class="w100 text-center"><strong>{{ metrics_1.total }} </strong></td>
                        <td class="w100 text-center"><strong> {{ metrics_1.done }}</strong></td>
                        <td class="w100 text-center"><strong> {{ metrics_1.validated }}</strong></td>
                        <td class="w100 text-center">{{ metrics_1.total > 0 ? Math.round(metrics_1.validated / metrics_1.total * 100) : 0}} %</td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>         
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-left"><strong> SUELDO </strong></td>
                        <td class="w100 text-right">$ {{ metrics_1.wage | currency }}</td>
                      </tr>
                      <tr>
                        <td class="text-left"><strong>POR PAGAR </strong></td>
                        <td class="w100 text-right">$ {{ metrics_1.wage_done | currency }}</td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>  
              </CCol>             
            </CRow>
            <span v-else>NO SE HAN DEFINIDO VISITAS PARA ESTA QUINCENA</span>
          </CCardBody>
        </CCard>
      </CCol>  
      <CCol :md="4" >
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Universo Quincena 2 
            </slot>
          </CCardHeader>
          <CCardBody :style="'height: '+setMetricHeight()+'px;'">
            <CRow v-if="metrics_2.visits.length > 0">
              <CCol :md="12">
                <CChartPie
                  :datasets="defaultDatasets2"
                  :labels="graph_2[0]"
                />
              </CCol>
              <CCol :md="12" >
                <br>
                <CDataTable
                  :hover="hover"
                  :striped="striped"
                  :border="border"
                  :small="small"
                  :fixed="fixed"
                  :items="metrics_2.visits"
                  :fields="fieldsMetric"
                  :items-per-page="5"
                  pagination
                  class="table-metrics"                  
                >
                  <template #marca="{item}"><td class="text-left">{{ item.customer_name }}</td></template>
                  <template #universo="{item}"><td class="w100 text-center">{{ item.total }}</td></template>
                  <template #realizado="{item}"><td class="w100 text-center">{{ item.done }}</td></template>      
                  <template #validated="{item}"><td class="w100 text-center">{{ item.validated }}</td></template>      
                  <template #porcentaje="{item}"><td class="w100 text-center">{{ Math.round(item.rate) }} %</td></template>
                </CDataTable>
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-center"><strong> TOTAL</strong></td>
                        <td class="w100 text-center"><strong>{{ metrics_2.total }} </strong></td>
                        <td class="w100 text-center"><strong> {{ metrics_2.done }}</strong></td>
                        <td class="w100 text-center"><strong> {{ metrics_2.validated }}</strong></td>
                        <td class="w100 text-center">{{ metrics_2.total > 0 ? Math.round(metrics_2.validated / metrics_2.total * 100) : 0}} %</td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>         
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-left"><strong> SUELDO </strong></td>
                        <td class="w100 text-right">$ {{ metrics_2.wage | currency }}</td>
                      </tr>
                      <tr>
                        <td class="text-left"><strong>POR PAGAR </strong></td>
                        <td class="w100 text-right">$ {{ metrics_2.wage_done | currency }}</td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>  
              </CCol>             
            </CRow>
            <span v-else>NO SE HAN DEFINIDO VISITAS PARA ESTA QUINCENA</span>
          </CCardBody>
        </CCard>
      </CCol>  
      <CCol :md="4" >
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Universo Mensual
            </slot>
          </CCardHeader>
          <CCardBody :style="'height: '+setMetricHeight()+'px;'">
            <CRow v-if="metrics_1.visits.length > 0 || metrics_2.visits.length > 0">
              <CCol :md="12">
                <CChartPie
                  :datasets="defaultDatasets3"
                  :labels="graph_3[0]"
                />
              </CCol>
              <CCol :md="12" >
                <br>
                <CDataTable
                  :hover="hover"
                  :striped="striped"
                  :border="border"
                  :small="small"
                  :fixed="fixed"
                  :items="metrics_3.visits"
                  :fields="fieldsMetric"
                  :items-per-page="5"
                  pagination
                  class="table-metrics"                  
                >
                  <template #marca="{item}"><td class="text-left">{{ item.customer_name }}</td></template>
                  <template #universo="{item}"><td class="w100 text-center">{{ item.total }}</td></template>
                  <template #realizado="{item}"><td class="w100 text-center">{{ item.done }}</td></template>      
                  <template #validated="{item}"><td class="w100 text-center">{{ item.validated }}</td></template>      
                  <template #porcentaje="{item}"><td class="w100 text-center">{{ Math.round(item.rate) }} %</td></template>
                </CDataTable>
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-center"><strong> TOTAL</strong></td>
                        <td class="w100 text-center"><strong>{{ metrics_3.total }} </strong></td>
                        <td class="w100 text-center"><strong>{{ metrics_3.done }}</strong></td>
                        <td class="w100 text-center"><strong>{{ metrics_3.validated }}</strong></td>
                        <td class="w100 text-center">{{ metrics_3.total > 0 ? Math.round(metrics_3.validated / metrics_3.total * 100) : 0}} %</td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>         
                <div class="position-relative table-responsive table-metrics">
                  <table class="table table-hover table-bordered">
                    <tbody class="position-relative">
                      <tr>
                        <td class="text-left"><strong> SUELDO </strong></td>
                        <td class="w100 text-right">$ {{ (metrics_1.wage + metrics_2.wage) | currency }}</td>
                      </tr>
                      <tr>
                        <td class="text-left"><strong>POR PAGAR </strong></td>
                        <td class="w100 text-right">$ {{ (metrics_1.wage_done * 1 + metrics_2.wage_done * 1) | currency }}</td>
                      </tr>
                    </tbody>                  
                  </table>
                </div>  
              </CCol>             
            </CRow>
            <span v-else>NO SE HAN DEFINIDO VISITAS PARA ESTE PERIODO</span>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol :md="6">
        <CCard >
          <CCardHeader>
            <CRow>
              <CCol md="3">
                <slot name="header">
                  <CIcon name="cil-grid"/> Visitas Quincena 1
                </slot>
              </CCol>                        
              <CCol md="4">
                  <CSelect
                    v-if="showFilters"
                    :value.sync="brandsSelect1" 
                    :options="brandsOptions"                    
                    @change="changeFilters1"
                  />                        
              </CCol>
              <CCol md="3">
                    <v-date-picker
                    v-if="showFilters"                    
                    v-model="date1"    
                    class="date-picker"    
                    :maxDate="maxDate1"
                    :minDate="minDate1"
                    locale="es-MX" size="sm"          
                    mode="date"
                  />   
              </CCol>
              <CCol md="2">
                <CButton style="float:right; margin-top: 5px;" color="success" size="sm" @click="downloadExcel('first-week')">Descargar Excel</CButton>
              </CCol>              
            </CRow>            
          </CCardHeader>
          <CCardBody  :style="'height: '+(70 + (Math.max(visits_1.length, visits_2.length) < 15 ? Math.max(visits_1.length, visits_2.length) * 48 : 645))+'px; overflow:auto;'">
            <CDataTable
              v-if="visits_1.length > 0"
              :hover="hover"
              :striped="striped"
              :border="border"
              :small="small"
              :fixed="fixed"
              :items="visits_1"
              :fields="fields"
              :items-per-page="15"
              clickable-rows
              sorter
              pagination
              class="table-visits"
            >
              <template #tienda="{item}"><td class="text-center">{{ item.branch_name }}</td></template>
              <template #marca="{item}"><td class="w250 text-center">{{ item.customer_name }}</td></template>
              <template #fecha="{item}"><td class="w150 text-center">{{ item.date | date }}</td></template>      
              <template #status="{item}"><td class="w150 text-center">{{ (item.status == 0) ? "Pendiente": "Completada"}}</td></template>
              <template #validation="{item}">
                <td class="w150 text-center" :style="'color: '+(item.is_validated >= 0 ? 'black' : 'red')+'; font-weight: bold;'">
                  {{ item.is_validated ? item.validation_date : '-' | date }}
                  <CButton style="width: 30px; height: 30px; padding: 0px; margin-left: 10px;" v-if="item.validation_comments != '' && item.validation_comments != null" v-c-tooltip="{content: item.validation_comments}" color="secondary">
                    <CIcon name="cilCommentBubble" style="width: 20px; height: 20px;"/>             
                  </CButton>
                </td>
              </template>
              <template #actions="{item}">
                <td style="padding: 5px; border: 6px !important!" class="w100 text-center">
                  <CButton color="info" size="sm" @click="viewVisit(item)">Ver Visita</CButton>                
                </td>
              </template>
            </CDataTable>
            <span v-else>NO SE HAN DEFINIDO VISITAS PARA ESTA QUINCENA</span>
          </CCardBody>
        </CCard>       
      </CCol>      
      <CCol :md="6">
        <CCard >
          <CCardHeader>
            <CRow>
              <CCol md="3">
                <slot name="header">
                  <CIcon name="cil-grid"/> Visitas Quincena 2
                </slot>
              </CCol>                        
              <CCol md="4">
                  <CSelect
                    v-if="showFilters"
                    v-model="brandsSelect2" 
                    :options="brandsOptions"
                    @change="changeFilters2"
                  />                        
              </CCol>
              <CCol md="3">
                  <v-date-picker           
                    v-if="showFilters"         
                    v-model="date2"    
                    class="date-picker"    
                    :maxDate="maxDate2"
                    :minDate="minDate2"
                    locale="es-MX" size="sm"          
                    mode="date"
                  />        
              </CCol>
              <CCol md="2">
                <CButton style="float:right; margin-top: 5px;" color="success" size="sm" @click="downloadExcel('second-week')">Descargar Excel</CButton>
              </CCol>              
            </CRow>            
          </CCardHeader>
          <CCardBody  :style="'height: '+(70 + (Math.max(visits_1.length, visits_2.length) < 15 ? Math.max(visits_1.length, visits_2.length) * 48 : 645))+'px; overflow:auto;'">
            <CDataTable
              v-if="visits_2.length > 0"
              :hover="hover"
              :striped="striped"
              :border="border"
              :small="small"
              :fixed="fixed"
              :items="visits_2"
              :fields="fields"
              :items-per-page="15"
              sorter
              pagination
              class="table-visits"
            >
              <template #tienda="{item}"><td class="text-center">{{ item.branch_name }}</td></template>
              <template #marca="{item}"><td class="w250 text-center">{{ item.customer_name }}</td></template>
              <template #fecha="{item}"><td class="w150 text-center">{{ item.date | date }}</td></template>      
              <template #status="{item}"><td class="w150 text-center">{{ (item.status == 0) ? "Pendiente": "Completada"}}</td></template>
              <template #validation="{item}">
                <td class="w150 text-center" :style="'color: '+(item.is_validated >= 0 ? 'black' : 'red')+'; font-weight: bold;'">
                  {{ item.is_validated ? item.validation_date : '-' | date }}
                  <CButton style="width: 30px; height: 30px; padding: 0px; margin-left: 10px;" v-if="item.validation_comments != '' && item.validation_comments != null" v-c-tooltip="{content: item.validation_comments}" color="secondary">
                    <CIcon name="cilCommentBubble" style="width: 20px; height: 20px;"/>             
                  </CButton>
                </td>
              </template>
              <template #actions="{item}">
                <td style="padding: 5px; border: 6px !important!" class="w100 text-center">
                  <CButton color="info" size="sm" @click="viewVisit(item)">Ver Visita</CButton>                
                </td>
              </template>
            </CDataTable>
            <span v-else>NO SE HAN DEFINIDO VISITAS PARA ESTA QUINCENA</span>
          </CCardBody>
        </CCard>       
      </CCol>  
    </CRow>
  </div>
</template>
<script>

import store from '../../store'
import router from '../../router/index'
import ws from '../../services/kam';
import moment from 'moment';
import { CChartPie } from '@coreui/vue-chartjs'
import { DatePicker } from 'v-calendar'



export default {
  name: 'KamRuta',
  components: {
    CChartPie,
    'v-date-picker': DatePicker
  },
  data () {
    return {      
      page: 'kamRuta',
      customer : {},
      collaborator: {
        user:{
          email: "",
          contrasena: "",
        },        
      },
      visits_1 : [],
      visits_2 : [],
      metrics_1 : {
        visits : [],
      },
      metrics_2 : {
        visits : [],
      },
      metrics_3 : [],
      graph_1 : [],
      graph_2 : [],
      graph_3 : [],
      backgroundColors:[
        '#41B883',
        '#E46651',
        '#00D8FF',
        '#DD1B16',
        '#2F4F4F',
        '#708090',	
        '#696969',
        '#A52A2A',
        '#8B4513',
        '#DAA520',
        '#BC8F8F',
        '#000080',
        '#7B68EE',
        '#ADD8E6',
        '#40E0D0',
        '#9ACD32',
        '#90EE90',
        '#ADFF2F',
        '#800080',
        '#6A5ACD',
        '#DDA0DD',
        '#BDB76B',
        '#FFFF00',
        '#FF69B4',
        '#CD5C5C',
        '#FF5733',
      ],
      id: 0,
      hover: true,
      striped: false,
      border: true,
      small: false,
      fixed: false,
      dark: true,
      fields: [
        {key: 'tienda', label: 'Tienda'},
        {key: 'marca', label: 'Marca'},
        {key: 'fecha', label: 'Fecha Visita', },
        {key: 'status', label: 'Estado'},
        {key: 'validation', label: 'Revisión'},
        {key: 'actions', label: ''},
      ],
      fieldsMetric: [        
        {key: 'marca', label: 'Marca'},
        {key: 'universo', label: 'Universo'},
        {key: 'realizado', label: 'Realizado'},
        {key: 'validated', label: 'Aceptado'},
        {key: 'porcentaje', label: '%'},
      ],
      brandsSelect1: "",
      brandsSelect2: "",
      brandsOptions: [],
      date1: "",
      date2: "",
      minDate1: new moment().startOf('month').toDate(),
      maxDate1: new moment().startOf('month').add("day",14).toDate(),
      minDate2: new moment().startOf('month').add("day",15).toDate(),
      maxDate2: new moment().endOf('month').toDate(),
      showFilters: false
    }
  },
  computed: {
    defaultDatasets1 () {
      return [
        {
          backgroundColor: this.backgroundColors,
          data: this.graph_1[1]
        }
      ]
    },
    defaultDatasets2 () {
      return [
        {
          backgroundColor: this.backgroundColors,
          data: this.graph_2[1]
        }
      ]
    },
    defaultDatasets3 () {
      return [
        {
          backgroundColor: this.backgroundColors,
          data: this.graph_3[1]
        }
      ]
    },
  },
  mounted: async function() {
    this.loading();
    this.id = await this.$route.params.id;

    let route_id = JSON.parse(localStorage.getItem("route_id"));

    let date = JSON.parse(localStorage.getItem("period_date"));
    this.minDate1 = new moment(date[0]).toDate();
    this.maxDate1 = new moment(date[0]).add("day",14).toDate();
    this.minDate2 = new moment(date[0]).add("day",15).toDate();
    this.maxDate2 = new moment(date[1]).toDate();
    
    let filters = {"date" : date};

    let last_login = localStorage.getItem("last_login");
    let today = moment().format("YYYY-MM-DD");

    if(route_id != this.id || last_login === null || last_login === undefined || last_login != today){
      last_login = today;
      localStorage.setItem("last_login", last_login);

      localStorage.removeItem("route_filter_date1");
      localStorage.removeItem("route_filter_date2");
      localStorage.removeItem("route_filter_brands1");
      localStorage.removeItem("route_filter_brands2");
      
      let response_collaborator = await ws.showCollaborator(this.id);
      if(response_collaborator.type == "success"){
        this.collaborator = response_collaborator.data;
        localStorage.setItem("collaborator", JSON.stringify(this.collaborator));
      }

      let response_visits = await ws.collaboratorVisits(this.id, filters);
      if(response_visits.type == "success"){
        this.visits_1 = response_visits.data.group_1;
        this.visits_2 = response_visits.data.group_2;
        localStorage.setItem("visits_1", JSON.stringify(this.visits_1));
        localStorage.setItem("visits_2", JSON.stringify(this.visits_2));
      }

      let response_metrics = await ws.collaboratorVisitsMetrics(this.id, filters);
      if(response_metrics.type == "success"){
        this.metrics_1 = response_metrics.data.group_1;
        this.metrics_2 = response_metrics.data.group_2;
        this.metrics_3 = response_metrics.data.group_3;
        this.graph_1 = response_metrics.data.graph_1;
        this.graph_2 = response_metrics.data.graph_2;
        this.graph_3 = response_metrics.data.graph_3;

        localStorage.setItem("metrics_1", JSON.stringify(this.metrics_1));
        localStorage.setItem("metrics_2", JSON.stringify(this.metrics_2));
        localStorage.setItem("metrics_3", JSON.stringify(this.metrics_3));
        localStorage.setItem("graph_1", JSON.stringify(this.graph_1));
        localStorage.setItem("graph_2", JSON.stringify(this.graph_2));
        localStorage.setItem("graph_3", JSON.stringify(this.graph_3));
      }
      let response_brands = await ws.collaboratorVisitBrands(this.id, filters);
      if(response_brands.type == "success"){
        this.brandsOptions = await this.$parseSelectOptionsOrdered(response_brands.data, "id", "name",[{"label": "Todas las Marcas","value":""}]);
        localStorage.setItem("route_brands", JSON.stringify(this.brandsOptions));
      }

      localStorage.setItem("route_id", JSON.stringify(this.id));
    }
    else{      
      let brands = JSON.parse(localStorage.getItem("route_brands"));
      if(brands === null || brands === undefined){
        let response_brands = await ws.collaboratorVisitBrands(this.id, filters);
        if(response_brands.type == "success"){
          this.brandsOptions = await this.$parseSelectOptionsOrdered(response_brands.data, "id", "name",[{"label": "Todas las Marcas","value":""}]);
          localStorage.setItem("route_brands", JSON.stringify(this.brandsOptions));
        }
      }else{
        this.brandsOptions = await brands;
      }

      let collaborator = JSON.parse(localStorage.getItem("collaborator"));
      if(collaborator === null || collaborator === undefined){
        let response_collaborator = await ws.showCollaborator(this.id);
        if(response_collaborator.type == "success"){
          this.collaborator = response_collaborator.data;

          localStorage.setItem("collaborator", JSON.stringify(this.collaborator));
        }
      }else{
        this.collaborator = collaborator;
      }

      let visits_1 = JSON.parse(localStorage.getItem("visits_1"));
      let visits_2 = JSON.parse(localStorage.getItem("visits_2"));

      if(visits_1 === null || visits_1 === undefined){
        let response_visits = await ws.collaboratorVisits(this.id, filters);
        if(response_visits.type == "success"){
          this.visits_1 = response_visits.data.group_1;
          this.visits_2 = response_visits.data.group_2;

          localStorage.setItem("visits_1", JSON.stringify(this.visits_1));
          localStorage.setItem("visits_2", JSON.stringify(this.visits_2));
        }
      }else{
        this.visits_1 = visits_1;
        this.visits_2 = visits_2;
      }

      let metrics_1 = JSON.parse(localStorage.getItem("metrics_1"));
      let metrics_2 = JSON.parse(localStorage.getItem("metrics_2"));
      let metrics_3 = JSON.parse(localStorage.getItem("metrics_3"));

      let graph_1 = JSON.parse(localStorage.getItem("graph_1"));
      let graph_2 = JSON.parse(localStorage.getItem("graph_2"));
      let graph_3 = JSON.parse(localStorage.getItem("graph_3"));

      if(metrics_1 === null || metrics_1 === undefined){
        let response_metrics = await ws.collaboratorVisitsMetrics(this.id, filters);
        if(response_metrics.type == "success"){
          this.metrics_1 = response_metrics.data.group_1;
          this.metrics_2 = response_metrics.data.group_2;
          this.metrics_3 = response_metrics.data.group_3;
          this.graph_1 = response_metrics.data.graph_1;
          this.graph_2 = response_metrics.data.graph_2;
          this.graph_3 = response_metrics.data.graph_3;

          localStorage.setItem("metrics_1", JSON.stringify(this.metrics_1));
          localStorage.setItem("metrics_2", JSON.stringify(this.metrics_2));
          localStorage.setItem("metrics_3", JSON.stringify(this.metrics_3));
          localStorage.setItem("graph_1", JSON.stringify(this.graph_1));
          localStorage.setItem("graph_2", JSON.stringify(this.graph_2));
          localStorage.setItem("graph_3", JSON.stringify(this.graph_3));
        }
      }else{
        this.metrics_1 = metrics_1;
        this.metrics_2 = metrics_2;
        this.metrics_3 = metrics_3;
        this.graph_1 = graph_1;
        this.graph_2 = graph_2;
        this.graph_3 = graph_3;
      }
      let date1 = localStorage.getItem("route_filter_date1");
      let date2 = localStorage.getItem("route_filter_date2");      
      if(date1 !== null && date1 !== undefined){
        this.date1 = moment(date1).toDate();
      }
      if(date2 !== null && date2 !== undefined){
        this.date2 = moment(date2).toDate();
      }
      let brand1 = localStorage.getItem("route_filter_brands1");
      let brand2 = localStorage.getItem("route_filter_brands2");
      if(brand1 !== null && brand1 !== undefined){
        this.brandsSelect1 = brand1*1;
      }
      if(brand2 !== null && brand2 !== undefined){
        this.brandsSelect2 = brand2*1;
      }    
    }

    let _this = this;

    _this.showFilters = false;
    setTimeout(function(){
      _this.showFilters = true;
    }, 100);

    this.loaded();
  },
  methods: {     
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;
      }
    },
    setMetricHeight () {      
      let max = Math.max(this.metrics_1.visits.length, this.metrics_2.visits.length);
      if(max == 0){
        return 75;
      }else if(max <= 5){
        return 500 +(68 * max);
      }else{
        return 720;
      }
    },
    link(type, item){
      switch (type) {
        case "home":
            this.$router.push('/welcome');
          break;
      
        default:
          break;
      }      
    },
    viewVisit(item){      
      localStorage.setItem("after_page", "rute");  
      this.$router.push('/visita/'+item.id);
    },
    async downloadExcel(type){      
      this.loading();
        let date = JSON.parse(localStorage.getItem("period_date"));
        let filters = {
          date1 : (this.date1 != "") ? moment(this.date1).format("YYYY-MM-DD") : "",
          date2 : (this.date2 != "") ? moment(this.date2).format("YYYY-MM-DD") : "",
          date: JSON.parse(localStorage.getItem("period_date")),
          brand1 : this.brandsSelect1,
          brand2 : this.brandsSelect2,
          type: type,
        };

        await ws.downloadExcel(this.id,filters);
      this.loaded();
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    async changeFilters1 (event) {
      if(event){
        this.brandsSelect1 = event.target.value;
      }

      this.loading();
      localStorage.setItem("route_filter_brands1", this.brandsSelect1);
      let filters = {
        date1 : (this.date1 != "") ? moment(this.date1).format("YYYY-MM-DD") : "",
        date2 : (this.date2 != "") ? moment(this.date2).format("YYYY-MM-DD") : "",
        date: JSON.parse(localStorage.getItem("period_date")),
        brand1 : this.brandsSelect1,
        brand2 : this.brandsSelect2,
        hasFilters : true,
      };
      let response_visits = await ws.collaboratorVisits(this.id, filters);
      if(response_visits.type == "success"){
        this.visits_1 = response_visits.data.group_1;
        this.visits_2 = response_visits.data.group_2;
        localStorage.setItem("visits_1", JSON.stringify(this.visits_1));
        localStorage.setItem("visits_2", JSON.stringify(this.visits_2));
      }

      this.loaded();
    },
    async changeFilters2 (event) {
      if(event){
        this.brandsSelect2 = event.target.value;
      }
      
      this.loading();
      
      localStorage.setItem("route_filter_brands2", this.brandsSelect2);
      console.log(this.brandsSelect1);
      let filters = {
        date1 : (this.date1 != "") ? moment(this.date1).format("YYYY-MM-DD") : "",
        date2 : (this.date2 != "") ? moment(this.date2).format("YYYY-MM-DD") : "",
        date: JSON.parse(localStorage.getItem("period_date")),
        brand1 : this.brandsSelect1,
        brand2 : this.brandsSelect2,
        hasFilters : true,
      };
      let response_visits = await ws.collaboratorVisits(this.id, filters);
      if(response_visits.type == "success"){
        this.visits_1 = response_visits.data.group_1;
        this.visits_2 = response_visits.data.group_2;
        localStorage.setItem("visits_1", JSON.stringify(this.visits_1));
        localStorage.setItem("visits_2", JSON.stringify(this.visits_2));
      }

      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  watch: {
    date1: async function(){
      if(this.date1 != ""){
        localStorage.setItem("route_filter_date1", moment(this.date1).format("YYYY-MM-DD"));
        this.changeFilters1(false)
      }      
    },
    date2: async function(){
      if(this.date2 != ""){
        localStorage.setItem("route_filter_date2", moment(this.date2).format("YYYY-MM-DD"));
        this.changeFilters2(false)
      }      
    },
  },
}
</script>

<style>
.table-metrics table {
  font-size: 12px !important;
}
.table-metrics table td{
  padding: 5px;
}

.table-visits table {
  font-size: 12px !important;
}
.table-visits table td{
  padding: 5px;
}
</style>
